import axios from 'axios';

const ax = axios.create({baseURL: "https://yctxojeod4.execute-api.ap-southeast-1.amazonaws.com/dev"});

export const getCurrentCarparks = () => {
    return new Promise((resolve, reject) => {
        ax.get('/api/current').then(res => {
            resolve(res.data[0].carpark);
        }, err => {
            reject(err);
        });
    });
};

export const getDumpCarparks = () => {
    return new Promise((resolve, reject) => {
        ax.get('/api/dump').then(res => {
            resolve(res.data);
        }, err => {
            reject(err);
        });
    });
};

export const getInfo = (type) => {
    return new Promise((resolve, reject) => {
        ax.get('/api/info?type=' + type).then(res => {
            resolve(res.data[0].data);
        }, err => {
            reject(err);
        });
    });
};