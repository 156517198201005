import React from 'react';
import './App.css';
import $ from 'jquery';
import { geolocated } from 'react-geolocated';

import LeafletMapContainer from './components/MapContainer/LeafletMapContainer';
import CarparkCard from './components/CarparkCard/CarparkCard';
import SearchBar from './components/SearchBar/SearchBar';
import Filter from './components/Filter/Filter';
import Toolbar from './components/Toolbar/Toolbar';
import Toast from './components/Toast/Toast';

import {getDumpCarparks, getInfo} from './controllers/RAMKY';

class App extends React.Component {

  defaultMap = {
      center: { lat: 1.297355, lng: 103.776722 }, // default NUS center 
      zoom: 16
  }

  state = {
    map: this.defaultMap,
    polyline: [],
    selectedCarpark: null,
    filters: {
      staff: true,
      public: true
    },
    nearestCarparks: [],
    rawData: {
      maxLots: {},
      carparkDump: []
    }
  }

  setMapControls = (lat, lng, zoom) => {
    let
    newMap = {}

    newMap.center = { lat: lat, lng: lng };
    newMap.zoom = zoom;

    this.setState({map: newMap});
  };

  setNearestCarparksHandler = (carparks) => {
    this.setState({nearestCarparks: carparks});
  };

  setPolylineHandler = (polyline) => {
    this.setState({polyline: polyline});
  };

  selectCarparkHandler = (carpark) => {
    this.setState({selectedCarpark: carpark});
    this.setMapControls(carpark.latitude, carpark.longitude, 18);
  };

  resetMapHandler = () => {
    $("#search-text").val('');
    this.setState({selectedCarpark: null, nearestCarparks: []});
    this.setMapControls(this.defaultMap.center.lat, this.defaultMap.center.lng, this.defaultMap.zoom);
  };

  toggleStaffHandler = () => {
    this.setState({
      filters: {
        staff: !this.state.filters.staff,
        public: this.state.filters.public
      }
    });
  };

  togglePublicHandler = () => {
    this.setState({
      filters: {
        staff: this.state.filters.staff,
        public: !this.state.filters.public
      }
    });
  };

  mapStateChangeHandler = (mapProps) => {
    this.setState({
      map: mapProps
    });
  };

  showToastHandler = () => {
  };

  async componentDidMount() {
    // max lots 
    let maxLots = await getInfo('max-lot');

    // carpark dump
    let carparkDump = await getDumpCarparks();

    this.setState({
      rawData: {
        maxLots: maxLots,
        carparkDump: carparkDump
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.coords !== prevProps.coords) { 
    }
  }

  render() {
    return (
      <div className="App">
        <SearchBar
          filters={this.state.filters}
          changeMap={(mapProps) => this.mapStateChangeHandler(mapProps)} 
          setCarpark={(carpark) => this.selectCarparkHandler(carpark)}
          setNearestCarparks={(carparks) => this.setNearestCarparksHandler(carparks)}
          setDirection={(direction) => this.setDirectionHandler(direction)}
          setPolyline={(polyline) => this.setPolylineHandler(polyline)} />
        {this.state.selectedCarpark ? 
        <CarparkCard 
          data={this.state.selectedCarpark} 
          rawData={this.state.rawData}
          nearestCarparks={this.state.nearestCarparks}
          setCarpark={(carpark) => this.selectCarparkHandler(carpark)}
          setDefault={() => this.resetMapHandler()} /> : ''}
        <Toolbar
          showToast={() => this.showToastHandler()}/>
        <Filter
          staff={this.state.filters.staff}
          public={this.state.filters.public}
          togglePublic={() => this.togglePublicHandler()}
          toggleStaff={() => this.toggleStaffHandler()}/>
        <LeafletMapContainer
          center={[this.state.map.center.lat, this.state.map.center.lng]}
          zoom={this.state.map.zoom}
          polyline={this.state.polyline}
          filters={this.state.filters}
          setDefault={() => this.resetMapHandler()}
          selectedCarpark={this.state.selectedCarpark}
          onCarparkClick={(carpark) => this.selectCarparkHandler(carpark)} />
        {/*<MapContainer 
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          center={this.state.map.center} 
          zoom={this.state.map.zoom} 
          direction={this.state.direction}
          selectedCarpark={this.state.carparkCard.data}
          setCarpark={(carpark) => this.markerClickHandler(carpark)} 
          setDefault={() => this.mapDefaultHandler()}
          clearDirection={() => this.setState({direction: null})} />*/}
      </div>
    );
  }
}
export default geolocated({positionOptions: { enableHighAccuracy: true }, userDecisionTimeout: 10000})(App);
