import React, {Component} from 'react';
import * as d3 from 'd3';
import "./BarChart.css";
import {getCurrentCarparks, getDumpCarparks, getInfo} from '../../controllers/RAMKY';

class BarChart extends Component {
    /*
    Required Props variables:
    width: number,
    height: number,
    carparkID: number
    */
   
    state = {
        currentCarparks: [],
        lastCarpark: 0
    }

    async componentDidMount() {
        console.log('[MOUNT] BarChart');
        // Current carpark
        let currentCarparks = await getCurrentCarparks();
        this.setState({currentCarparks: currentCarparks});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.rawData.carparkDump.length > 0 && Object.keys(this.props.rawData.maxLots).length > 0 && this.state.currentCarparks.length > 0) {
            if (this.state.lastCarpark !== this.props.carparkID) {
                (this.props.mobile) ? d3.selectAll("#barchart-mobile > *").remove() : d3.selectAll("#barchart > *").remove();
                console.log('updated barchart');
                this.createBarChart();
                this.setState({lastCarpark: this.props.carparkID})
            }
        } 
    }



    createBarChart() {
        console.log('created bar chart');
        const node = this.node;
        const carparkData = this.props.rawData.carparkDump;
        const currentCarparkData = this.state.currentCarparks;
        //Decision variables
        //Bar Chart variables
        let carparkID = this.props.carparkID;
        let sizeHeight = this.props.height;
        let sizeWidth = this.props.width;
        let yAxisLimits = [0, 1]; //Change this to make bars look more full
        let xTickLabels = ["6a","9a","12p","3p","6p","9p"];
        let xTickValues = [6,9,12,15,18,21];
        let barColor = "#71a9f7";
        let timeOffSet = 16;
        //Palette = https://coolors.co/083d77-ebebd3-da4167-f4d35e-f78764
        //Time Slider Variables
        let lastCarparkDatapoint = currentCarparkData.filter(d => d.id === carparkID)[0]; //Last update
        let lastHour = new Date().getHours();
        let filteredDay = new Date().toLocaleDateString('en', {weekday: 'short'});
        let filteredStartHour = 5
        let filteredEndHour = 23
        let sliderColor = "#da4167"
        let sliderOpacity = 0.7
        // set the dimensions and margins of the graph
        var margin = {
            top: sizeHeight * 0,
            right: sizeWidth * 0,
            bottom: 20, //ensure axis will always be visible
            left: sizeWidth * 0
        },
        width = sizeWidth - margin.left - margin.right,
        height = sizeHeight - margin.top - margin.bottom;

        // Functions to extract parts
        let getHour = d => d = (new Date(d).getHours() + timeOffSet) %24;
        let getDay = d => d = new Date(d).toLocaleDateString('en', {weekday: 'short'});
        let getLots = d => d['lots'];
        let isWithinHours = d => d >= filteredStartHour && d <=filteredEndHour

        function addDatapoint(calDict, hour, lot) {
            if (! calDict[hour]) {
                calDict[hour] = {
                    "Total": lot,
                    "Count": 1
                }
            } else {
                let cur = calDict[hour]
                calDict[hour] = {
                    "Total": lot + cur["Total"],
                    "Count": cur["Count"] + 1
                }
            }
        }
        let calDict = {}
        carparkData.filter(collection => getDay(collection.datetime) === filteredDay )
            .forEach(collection => {
                let hour = getHour(collection.datetime);
                    if (isWithinHours(hour)){   
                        collection.carpark.filter(d => d.id === carparkID)
                            .forEach(d => {addDatapoint(calDict, hour, getLots(d))})
                            }
                    })
             
        // Labels of row and columns
        const range = (start, end) => Array.from({length: (end+1 - start)}, (v, k) => k + start);
        var hourGroup = range(filteredStartHour,filteredEndHour)

        //Get datapoints for ploting
        let aggData = []
        let carparkMaxLots = this.props.rawData.maxLots[carparkID];
        hourGroup.forEach(h => {
            let inner = {}
            let cur = calDict[h]
            inner["Hour"] = h
            inner["Availability"] = (cur['Total'] / cur['Count'])/ carparkMaxLots
            aggData.push(inner)
            })

        // Create x and y scales
        var x = d3.scaleBand().range([0, width]).padding(0.1);
        var y = d3.scaleLinear().range([height, 0]);

        // append the svg object to the body of the page
        // append a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        var svg = d3.select(node)
        .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
        .append("g")
            .attr("transform", 
                "translate(" + margin.left + "," + margin.top + ")");

        // Scale the range of the data in the domains
        x.domain(aggData.map(function(d) { return d.Hour; }));
        y.domain(yAxisLimits);

        // append the rectangles for the bar chart
        svg.selectAll(".bar")
            .data(aggData)
            .enter().append("rect")
            .attr("class", "bar")
            .attr("x", function(d) { return x(d.Hour); })
            .attr("width", x.bandwidth())
            .attr("y", y(0))
            .attr("height",  height - y(0) )
            .attr("fill",barColor)
            .attr("ry", 5);

        //Animations
        svg.selectAll("rect")
            .data(aggData)
            .transition()
            .duration(800)
            .attr("y", d => y(1 - d.Availability) )
            .attr("height", d => height - y(1 - d.Availability))
            .delay((d,i) => i*100)

        //Add the x Axis
        svg.append("g")
            .attr("transform", "translate(0," + height + ")") 
            .transition()
            .call(d3.axisBottom(x)
            .tickValues(xTickValues)
            .tickFormat((d,i) => xTickLabels[i]))
            .attr("class","axis")
            .attr("stroke-width",1);

        //Append time slider
        //Filter the amount of hours displayed on the barchart.
        if (lastHour >=filteredStartHour && lastHour<= filteredEndHour) {
            // Ensure bar is still shown when number of lots is 0
            let curAvailability = (lastCarparkDatapoint["lots"] === this.props.rawData.maxLots[carparkID] ? 
                0.999 : lastCarparkDatapoint["lots"] / this.props.rawData.maxLots[carparkID])
            svg.append("rect")
                .attr("x", x(lastHour))
                .attr("y", () => y(0) )
                .attr("height", () => height - y(0))
                .attr("width", x.bandwidth())
                .attr("fill", sliderColor)
                .attr("opacity",sliderOpacity)
                .attr('ry',5)
                .transition()
                .duration(800)
                .attr("y", y(1-curAvailability))
                .attr("height", height - y(1 - curAvailability))
                .delay(lastHour*100)
                .transition()
                .duration(1000)
                .attr("opacity","1")
                .transition()
                .duration(1000)
                .attr("opacity",sliderOpacity)
        }
        
    }

    render() {
        return <svg id={this.props.mobile ? 'barchart-mobile' : 'barchart'} ref={
                node => this.node = node
            }
            width={this.props.width}
            height={this.props.height}></svg>
    }
}
export default BarChart;
