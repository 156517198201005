import React from 'react';
import { Map, Marker, TileLayer, Popup, Tooltip, Polyline } from 'react-leaflet';
import {getCurrentCarparks} from '../../controllers/RAMKY';

class LeafletMapContainer extends React.Component {

    state = {
        carparks: [],
        markers: []
    }

    async componentDidMount() {
        let carparks = await getCurrentCarparks();
        this.setState({carparks: carparks});
    }

    shouldComponentUpdate(nextProps, nextStates) {
        return true;
    }

    componentDidUpdate(prevProps, prevStates) {
        console.log('leaflet map container re-rendered');
    }

    render() {

        let filteredCarpark = this.state.carparks;
        if (this.props.filters.staff && !this.props.filters.public) filteredCarpark = this.state.carparks.filter(carpark => carpark.type === '(Staff only)');
        if (!this.props.filters.staff && this.props.filters.public) filteredCarpark = this.state.carparks.filter(carpark => carpark.type !== '(Staff only)');
        if (!this.props.filters.staff && !this.props.filters.public) filteredCarpark = []

        let markers = filteredCarpark.map(carpark => {
            return (
            <Marker 
                key={carpark.id}    
                position={[carpark.latitude, carpark.longitude]}
                opacity={this.props.selectedCarpark === null || (this.props.selectedCarpark !== null && this.props.selectedCarpark.id === carpark.id) ? 1 : 0.3}
                onclick={() => this.props.onCarparkClick(carpark)}>

                <Popup><h5>{carpark.name}</h5> {carpark.caption}</Popup> 
                <Tooltip><b>{carpark.name}</b> {carpark.caption}</Tooltip>

            </Marker>
            )
        });

        return (
        <div className='map-container'>
            <Map 
                center={this.props.center} 
                zoom={this.props.zoom}
                ondblclick={() => this.props.setDefault()}
                zoomControl={false}>
                <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    url='https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}'
                    id='mapbox/streets-v11'
                    tileSize={512}
                    zoomOffset={-1}
                    maxZoom={20}
                    accessToken={process.env.REACT_APP_MAPBOX_API_KEY}/>
                
                {markers}

                <Polyline
                    key={123}
                    positions={this.props.polyline}
                    color={'red'}/>

            </Map>
        </div>
        )
    }
}

export default LeafletMapContainer;