import React from 'react';

class Filter extends React.Component {
    state = {
        public: false,
        staff: false
    }

    render() {
        return (
            <div className='filter-box'>
                <h5 className='mr-3'>Filter Carpark</h5>
                <input type='checkbox' value='public' checked={this.props.public} onChange={() => this.props.togglePublic()}/> Public 
                <input type='checkbox' value='staff' className='ml-3' checked={this.props.staff} onChange={() => this.props.toggleStaff()}/> Staff
            </div>
        )
    }
}

export default Filter;