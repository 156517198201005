import React from 'react';
import _ from 'underscore';
import $ from 'jquery';
import * as moment from 'moment';
import BarChart from '../BarChart/BarChart';
import HeatMap from '../HeatMap/HeatMap';
import {getInfo} from '../../controllers/RAMKY';

import googleMapIcon from '../../assets/images/google-maps-icon-old.png'; 
import wazeIcon from '../../assets/images/waze.png'; 

class CarparkCard extends React.Component {

    state = {
        maxLots: 0,
        currentPrice: 0,
        carparkPriceData: {},
        nearestCarparkIndex: 0,
        tabs: {
            crowd: false,
            heatmap: false
        }
    }

    async getMaxLotHandler() {
        // get max lots (efficiency can be improved by just fetching once) TODO
        let maxLots = await getInfo('max-lot');
        this.setState({maxLots: maxLots[this.props.data.id]});
    };

    async getPricingHandler() {
        // get pricing 
        let prices = await getInfo('lot-price');
        let carparkPrice = _.first(prices.filter(price => price.carparkName === this.props.data.name));
        this.setState({carparkPriceData: carparkPrice});
        let day = moment().day();

        if (carparkPrice && !carparkPrice.staff) {
            let priceData = [];
            if (day === 6) { // saturday
                priceData = carparkPrice.saturday; // array 
            } else if (day !== 0) { // weekdays 
                priceData = carparkPrice.weekday;
            }

            priceData.some(price => {
                let startTime = moment(price.start, 'HHmm');
                let endTime = moment(price.end, 'HHmm');
                let currentTime = moment();

                if (currentTime.isBetween(startTime, endTime)) {
                    this.setState({currentPrice: price.price});
                    return true; // short circuit 'some'
                }
            });
        }
    }

    prevNearestCarparkHandler = () => {
        let prevCarpark = this.props.nearestCarparks[this.state.nearestCarparkIndex - 1];
        this.props.setCarpark(prevCarpark);
    };

    nextNearestCarparkHandler = () => {
        let nextCarpark = this.props.nearestCarparks[this.state.nearestCarparkIndex + 1];
        this.props.setCarpark(nextCarpark);
    };

    setInfoTabHandler = () => {
        this.setState({
            tabs: {
                crowd: false,
                heatmap: false
            }
        })
    };

    setCrowdTabHandler = () => {
        this.setState({
            tabs: {
                crowd: true,
                heatmap: false
            }
        })
    };

    setHeatMapTabHandler = () => {
        this.setState({
            tabs: {
                crowd: false,
                heatmap: true
            }
        })
    };

    async componentDidMount() {
        await this.getMaxLotHandler();
        await this.getPricingHandler();

        // current carpark index under nearestCarparks
        let currentCarparkIndex = this.props.nearestCarparks.findIndex(carpark => carpark.id === this.props.data.id);
        if (this.props.nearestCarparks) this.setState({nearestCarparkIndex: currentCarparkIndex});
    }

    async componentWillUpdate() {

    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            await this.getMaxLotHandler();
            await this.getPricingHandler();
            
            // current carpark index under nearestCarparks
            let currentCarparkIndex = this.props.nearestCarparks.findIndex(carpark => carpark.id === this.props.data.id);
            if (this.props.nearestCarparks) this.setState({nearestCarparkIndex: currentCarparkIndex});
        }
    }

    render() {
        let price = this.state.currentPrice === 0 ? 'FREE' : '$' + this.state.currentPrice + '/min';

        let weekdayPrice = 0;
        let saturdayPrice = 0;

        if (this.state.carparkPriceData !== undefined && !this.state.carparkPriceData.staff && Object.keys(this.state.carparkPriceData).length > 0) {
            weekdayPrice = this.state.carparkPriceData.weekday.map(price => {
                return price.start + ' - ' + price.end + 'hrs $' + price.price + '/min';
            });
            saturdayPrice = this.state.carparkPriceData.weekday.map(price => {
                return price.start + ' - ' + price.end + 'hrs $' + price.price + '/min';
            });
        }

        let carparkBtnDiv = (
            <React.Fragment>
                <div className='col-xl-6'>
                    <button 
                        className='carpark-card-btn' 
                        disabled={this.state.nearestCarparkIndex === 0 ? true : false}
                        onClick={() => this.prevNearestCarparkHandler()}>
                        <i class="fas fa-chevron-left mr-2"></i> Prev Carpark
                    </button>
                </div>
                <div className='col-xl-6'>
                    <button 
                        className='carpark-card-btn' 
                        disabled={this.state.nearestCarparkIndex === this.props.nearestCarparks.length - 1 ? true : false}
                        onClick={() => this.nextNearestCarparkHandler()}>
                        Next Carpark <i class="fas fa-chevron-right"></i>
                    </button>
                </div>
            </React.Fragment>
        );

        let carparkBtnMobileDiv = (
            <div className='col-6 text-right'>
                <button 
                    className='carpark-card-btn mr-2' 
                    disabled={this.state.nearestCarparkIndex === 0 ? true : false}
                    onClick={() => this.prevNearestCarparkHandler()}>
                    <i class="fas fa-chevron-left"></i>
                </button>
                <button 
                    className='carpark-card-btn' 
                    disabled={this.state.nearestCarparkIndex === this.props.nearestCarparks.length - 1 ? true : false}
                    onClick={() => this.nextNearestCarparkHandler()}>
                    <i class="fas fa-chevron-right"></i>
                </button>
            </div>
        );

        return (
            <div className='carpark-card-wrapper'>
                <div className='carpark-card-btn-group-mobile'>
                    <div className='row'>
                        <div className='col-6 text-left navigation-content'>
                            <a href={`https://www.google.com/maps/search/?api=1&query=${this.props.data.latitude},${this.props.data.longitude}`} target='_blank' rel='noopener noreferrer'>
                                <img src={googleMapIcon} width='20%' className='mr-2' alt='Google Maps Icon'/>
                            </a>
                            <a href={`https://waze.com/ul?ll=${this.props.data.latitude},${this.props.data.longitude}`} target='_blank' rel='noopener noreferrer'>
                                <img src={wazeIcon} width='20%' alt='Waze Icon'/>
                            </a>
                        </div>
                        {this.props.nearestCarparks.length > 0 ? carparkBtnMobileDiv : null}
                    </div>
                </div>
                <div className='carpark-card'>
                    <div className='title'>
                        <div className='row'>
                            <div className='col-10'>
                                <span className="badge badge-pill badge-carpark-no">{this.props.data.name}</span>
                                <h1 className='ml-2 mb-0 d-inline-block'>{this.props.data.caption}</h1>
                            </div>
                            <div className='col-2 text-right'>
                                <i className="fas fa-times" style={{cursor: 'pointer'}} onClick={() => this.props.setDefault()}></i>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-6 text-center">
                            <div className='summary-header'>Available Lots</div>
                            <div className='summary-info'>{this.props.data.lots}/{this.state.maxLots}</div>
                        </div>
                        <div className="col-6 text-center">
                            <div className='summary-header'>Current Price</div>
                            <div className='summary-info'>{this.state.carparkPriceData.staff ? 'SEASON' : price}</div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <ul className="nav nav-pills nav-fill mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="pills-info-tab" data-toggle="pill" href="#pills-info" role="tab" aria-controls="pills-info" aria-selected="true" onClick={() => this.setInfoTabHandler()}>
                                        <i className="fas fa-info-circle"></i> Info
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="pills-crowd-tab" data-toggle="pill" href="#pills-crowd" role="tab" aria-controls="pills-crowd" aria-selected="false" onClick={() => this.setCrowdTabHandler()}>
                                        <i className="far fa-chart-bar"></i> Crowd
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="pills-heatmap-tab" data-toggle="pill" href="#pills-heatmap" role="tab" aria-controls="pills-heatmap" aria-selected="false" onClick={() => this.setHeatMapTabHandler()}>
                                        <i className="fas fa-chart-area"></i> Heatmap
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-info" role="tabpanel" aria-labelledby="pills-info-tab">
                                    <div className='row'>
                                        <div className='col-12 text-center'>
                                            <div className='pricing-header'>PRICING DETAILS</div>
                                        </div>
                                    </div>
                                    { !this.state.carparkPriceData.staff && 
                                    <div className='row mt-2'>
                                        <div className='col-6 text-center'>
                                            <b>Weekday</b>
                                            <div>
                                                {weekdayPrice}
                                            </div>
                                        </div>
                                        <div className='col-6 text-center'>
                                            <b>Saturday</b>
                                            <div>
                                                {saturdayPrice}
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    { this.state.carparkPriceData.staff && 
                                    <div className='row mt-2'>
                                        <div className='col-12 text-center'>
                                            Carpark is under Season Parking Pricing
                                        </div>
                                    </div>
                                    }
                                    <div className='row mt-3'>
                                        <div className='col-12 text-center'>
                                            <div className='pricing-header'>NAVIGATION</div>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-12 text-center navigation-content'>
                                            <a href={`https://www.google.com/maps/search/?api=1&query=${this.props.data.latitude},${this.props.data.longitude}`} target='_blank' rel='noopener noreferrer'>
                                                <img src={googleMapIcon} width='12%' className='mr-2' alt='Google Maps Icon'/>
                                            </a>
                                            <a href={`https://waze.com/ul?ll=${this.props.data.latitude},${this.props.data.longitude}`} target='_blank' rel='noopener noreferrer'>
                                                <img src={wazeIcon} width='12%' alt='Waze Icon'/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade text-center" id="pills-crowd" role="tabpanel" aria-labelledby="pills-crowd-tab">
                                    {this.state.tabs.crowd ? <BarChart 
                                        width={300} 
                                        height={150} 
                                        rawData={this.props.rawData}
                                        mobile={false}
                                        carparkID={this.props.data.id} /> : null }
                                </div>
                                <div className="tab-pane fade text-center" id="pills-heatmap" role="tabpanel" aria-labelledby="pills-heatmap-tab">
                                    {this.state.tabs.heatmap ? <HeatMap 
                                        width={300} 
                                        height={200} 
                                        rawData={this.props.rawData}
                                        mobile={false}
                                        carparkID={this.props.data.id} /> : null }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='carpark-card-mobile'>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-info-mobile" role="tabpanel" aria-labelledby="pills-info-tab">
                            <div className='title'>
                                <div className='row'>
                                    <div className='col-10'>
                                        <span className="badge badge-pill badge-carpark-no">{this.props.data.name}</span>
                                        <h1 className='ml-2 mb-0 d-inline-block'>{this.props.data.caption}</h1>
                                    </div>
                                    <div className='col-2 text-right'>
                                        <i className="fas fa-times" style={{cursor: 'pointer'}} onClick={() => this.props.setDefault()}></i>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-6 text-center">
                                    <div className='summary-header'>Available Lots</div>
                                    <div className='summary-info'>{this.props.data.lots}/{this.state.maxLots}</div>
                                </div>
                                <div className="col-6 text-center">
                                    <div className='summary-header'>Current Price</div>
                                    <div className='summary-info'>{this.state.carparkPriceData.staff ? 'SEASON' : price}</div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade text-center" id="pills-crowd-mobile" role="tabpanel" aria-labelledby="pills-crowd-tab">
                            <div className='row'>
                                <div className='col-10 text-center'>
                                    {this.state.tabs.crowd ? <BarChart 
                                        width={300} 
                                        height={150} 
                                        rawData={this.props.rawData}
                                        mobile={true}
                                        carparkID={this.props.data.id} /> : null }
                                </div>
                                <div className='col-2 text-right'>
                                    <i className="fas fa-times" style={{cursor: 'pointer'}} onClick={() => this.props.setDefault()}></i>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade text-center" id="pills-heatmap-mobile" role="tabpanel" aria-labelledby="pills-heatmap-tab">
                            <div className='row'>
                                <div className='col-10 text-center'>
                                    {this.state.tabs.heatmap ? <HeatMap 
                                        width={300} 
                                        height={175} 
                                        rawData={this.props.rawData}
                                        mobile={true}
                                        carparkID={this.props.data.id} /> : null }
                                </div>
                                <div className='col-2 text-right'>
                                    <i className="fas fa-times" style={{cursor: 'pointer'}} onClick={() => this.props.setDefault()}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <ul className="nav nav-pills nav-fill mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="pills-info-tab" data-toggle="pill" href="#pills-info-mobile" role="tab" aria-controls="pills-info" aria-selected="true" onClick={() => this.setInfoTabHandler()}>
                                        <i className="fas fa-info-circle"></i> Info
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="pills-crowd-tab" data-toggle="pill" href="#pills-crowd-mobile" role="tab" aria-controls="pills-crowd" aria-selected="false" onClick={() => this.setCrowdTabHandler()}>
                                        <i className="far fa-chart-bar"></i> Crowd
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="pills-heatmap-tab" data-toggle="pill" href="#pills-heatmap-mobile" role="tab" aria-controls="pills-heatmap" aria-selected="false" onClick={() => this.setHeatMapTabHandler()}>
                                        <i className="fas fa-chart-area"></i> Heatmap
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                { this.props.nearestCarparks.length > 0 ?
                <div className='carpark-card-btn-group'>
                    <div className='row'>
                        {carparkBtnDiv}
                    </div>
                </div>
                : null }
            </div>
        );
    }
}

export default CarparkCard;