import React from 'react';
import Fuse from "fuse.js";
import $ from 'jquery';
import _ from 'underscore';
import 'jquery-ui-bundle';
import decodePolyline from 'decode-google-map-polyline';

import { getCurrentCarparks, getInfo } from '../../controllers/RAMKY';

class SearchBar extends React.Component {

    state = {
        carparks: []
    }

    updateCarparks = async () => {
        let carparks = await getCurrentCarparks();
        if (this.props.filters.staff && !this.props.filters.public) carparks = carparks.filter(carpark => carpark.type === '(Staff only)');
        if (!this.props.filters.staff && this.props.filters.public) carparks = carparks.filter(carpark => carpark.type !== '(Staff only)');
        if (!this.props.filters.staff && !this.props.filters.public) carparks = []
        this.setState({carparks: carparks});
    };

    async componentDidMount() {
        let searchData = []

        let buildingData = await getInfo('building');
        buildingData.forEach(building => {
            building.keywords.forEach(keyword => 
                searchData.push({
                    label: keyword, 
                    value: building.coords
                }))
        });

        await this.updateCarparks();
        this.state.carparks.forEach(carpark => {
            searchData.push({
                label: carpark.name,
                value: [carpark.latitude, carpark.longitude]
            })
        });

        //sort search data such that suggestions appear in alphabetical order with carparks first
        searchData.sort((a,b) => {
            if (a.label.startsWith("CP") && !b.label.startsWith("CP")) {
                return -1;
            } else {
                return a.label.localeCompare(b.label);
            }
        });
        
        const getNearestCarpark = (coords) => {

            const getDistanceResults = (origin) => {
                let carparkWithDistance = this.state.carparks.map(carpark => {
                    let distance = Math.sqrt(Math.pow((origin.latitude - carpark.latitude), 2) + Math.pow((origin.longitude - carpark.longitude), 2));
                    carpark.distance = distance;
                    return carpark;
                });

                return carparkWithDistance.sort((a, b) => a.distance - b.distance);
            };

            return new Promise((resolve, reject) => {
                let directionsService = new window.google.maps.DirectionsService();
                let carparkDistances = getDistanceResults(coords);
                let top5Carparks = _.first(carparkDistances, 5);

                let gMapsCarparkQuery = Promise.all(top5Carparks.map(carpark => {
                    return new Promise((resolve, reject) => {
                        let directions = {
                            origin: new window.google.maps.LatLng(carpark.latitude, carpark.longitude),
                            destination: new window.google.maps.LatLng(coords.latitude, coords.longitude),
                            travelMode: window.google.maps.TravelMode.WALKING 
                        };

                        directionsService.route(directions, (result, status) => {
                            if (status === 'OK') {
                                carpark.distance = _.first(_.first(result.routes).legs).duration.value;

                                let polyline = decodePolyline(_.first(result.routes).overview_polyline).map(path => [path.lat, path.lng]);
                                carpark.polyline = polyline;

                                resolve(carpark);
                            } else {
                                reject();
                            }
                        });
                    });
                }));

                gMapsCarparkQuery.then(carparks => {
                    resolve(carparks.sort((a, b) => a.distance - b.distance));
                }, err => {
                    reject(err);
                });
            });
        }

        $("#search-text").autocomplete({
            autoFocus: false,
            source: function(request, response) {
                let f = new Fuse(searchData, {keys: ['label']});
                let fuzzyResult = f.search(request.term);
                let outputResult = $.map(fuzzyResult, (val) => searchData[val.refIndex]);
                response(outputResult);
            },
            focus: (event, ui) => {
                event.preventDefault();
                $("#search-text").val(ui.item.label);
                return false;
            },
            select: async (event, ui) => {
                event.preventDefault();
                let lat = ui.item.value[0];
                let lng = ui.item.value[1];
                let coords = { latitude: lat, longitude: lng };
                let nearestCarparks = await getNearestCarpark(coords);
                this.props.setNearestCarparks(nearestCarparks);
                this.props.setCarpark(_.first(nearestCarparks));
                
                //this.props.setPolyline(_.first(nearestCarparks).polyline);
                return false;
            },
            open: (event, ui) => {
                $(".search-bar").css('border-radius', '16px 16px 0 0');
            },
            close: (event, ui) => {
                $(".search-bar").css('border-radius', '16px');
            }
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.filters !== prevProps.filters) {
            this.updateCarparks();
        }
    }

    render() {
        return (
            <div className='search-bar'>
                <input id='search-text' type='text' placeholder='Search NUS Buildings, CP numbers!'/>
            </div>
        )
    }
}

export default SearchBar;