import React from 'react';

class Toolbar extends React.Component {
    render() {
        return (
            <div className='toolbar'>
                <ul>
                    {/* <li><i class="fas fa-location-arrow" onClick={() => this.props.showToast()}></i></li> */}
                    <li class='selected'><i class="fas fa-filter"></i></li>
                </ul>
            </div>
        )
    }
}

export default Toolbar;