import React, {Component} from 'react';
import * as d3 from 'd3';
import {getDumpCarparks, getInfo} from '../../controllers/RAMKY';

class HeatMap extends Component {
    state = {
        carparks: [],
        maxLots : {},
        lastCarpark: 0
    }

    constructor(props) {
        super(props)
        this.createHeatMap = this.createHeatMap.bind(this)
    }

    async componentDidMount() {
        if (this.props.rawData.carparkDump.length > 0 && Object.keys(this.props.rawData.maxLots).length > 0) {
            this.createHeatMap();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.rawData.carparkDump.length > 0 && Object.keys(this.props.rawData.maxLots).length > 0) {
            if (this.state.lastCarpark !== this.props.carparkID) {
                (this.props.mobile) ? d3.selectAll("#heatmap-mobile > *").remove() : d3.selectAll("#heatmap > *").remove();
                this.createHeatMap();
                this.setState({lastCarpark: this.props.carparkID})
            }
        } 
    }

    createHeatMap() {
        const node = this.node
        const carparkData = this.props.rawData.carparkDump        
        //Decision variables
        //HeatMap Variables
        let carparkID = this.props.carparkID
        let colorScale = [0.7,1]
        let heatMapColour = ["#da4167", "#FFEDAD"]
        let sizeHeight = this.props.height
        let sizeWidth = this.props.width
        let xTickLabels = ["6a","9a","12p","3p","6p","9p"]
        let xTickValues = [6,9,12,15,18,21]
        let font = "Arial"
        let timeOffSet = 16
        let fontSize = "0.6em" 
        
        //Time Slider Variables

        let filteredStartHour = 5
        let filteredEndHour = 22
        let lastHour = new Date().getHours() -1
        let lineColour = `#ED1C22`
        let lineWidth = `1.5`
        // set the dimensions and margins of the graph
        var margin = {
            top: 50, //ensure axis will always be visible
            right: sizeWidth * 0,
            bottom: sizeHeight * 0, 
            left: 40 //ensure axis will always be visible
            },
        width = sizeWidth - margin.left - margin.right,
        height = sizeHeight - margin.top - margin.bottom;

        var blockLength = width / (filteredEndHour - filteredStartHour)

        //Palette = https://coolors.co/083d77-ebebd3-da4167-f4d35e-f78764

        // Functions to extract parts
        let getHour = d => d = (new Date(d).getHours() + timeOffSet) %24;
        let getDay = d => d = new Date(d).toLocaleDateString('en', {weekday: 'short'});
        let getLots = d => d['lots'];
        let isWithinHours = d => d >= filteredStartHour && d <=filteredEndHour

        function addDatapoint(calDict, day, hour, lot) {
            if (! calDict[day]) {
                calDict[day] = {}
                calDict[day][hour] = {
                    "Total": lot,
                    "Count": 1
                }
            } else if (! calDict[day][hour]) {
                calDict[day][hour] = {
                    "Total": lot,
                    "Count": 1
                }
            } else {
                let cur = calDict[day]
                calDict[day][hour] = {
                    "Total": lot + cur[hour]["Total"],
                    "Count": cur[hour]["Count"] + 1
                }
            }
        }
        let calDict = {}
        carparkData.forEach(collection => {
                let day = getDay(collection.datetime)
                let hour = getHour(collection.datetime);
                    if (isWithinHours(hour)){   
                        collection.carpark.filter(d => d.id === carparkID)
                            .forEach(d => {addDatapoint(calDict, day, hour, getLots(d))})
                            }
                    })
        //Define Groups
        const range = (start, end) => Array.from({length: (end+1 - start)}, (v, k) => k + start);
        var hourGroup = range(filteredStartHour,filteredEndHour)
        var dayGroup = ["Sun","Sat","Fri","Thu","Wed","Tue","Mon"]

        //Get datapoints for ploting
        let aggData = []
        let carparkMaxLots = this.props.rawData.maxLots[carparkID]
            hourGroup.forEach(h => {
            dayGroup.forEach(c => {
            let inner = {}
            let cur = calDict[c][h]
            inner["Day"] = c
            inner["Hour"] = h
            inner["Availability"] = (cur['Total'] / cur['Count']) / carparkMaxLots
            aggData.push(inner)
                })
            })

        // append the svg object to the body of the page
        // append a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        var svg = d3.select(node)
        .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
        .append("g")
            .attr("transform", 
                "translate(" + margin.left + "," + margin.top + ")")
                .attr("font-family",font);

        // Build X scales and axis:
        let x = d3.scaleBand().range([0, width]).domain(hourGroup).padding(0);
        svg.append("g")
            .style("font-size", fontSize)
            .style("font-family",font)
            .attr("transform", "translate(0," + 0 + ")")
            .attr('stroke-width', 0)
            .transition()
            .call(d3.axisTop(x)
            .tickValues(xTickValues)
            .tickFormat((d,i) => xTickLabels[i]))
            .select(".domain").remove()

        // Build Y scales and axis:
        var y = d3.scaleBand().range([blockLength *7,0]).domain(dayGroup).padding(0.01);
        svg.append("g")
            .style("font-size", fontSize)
            .style("font-family",font)                          
            .attr('stroke-width', 0)
            .transition()
            .call(d3.axisLeft(y))
            .select(".domain").remove();

        // Build color scale
        var myColor = d3.scaleLinear()
            .range(heatMapColour)
            .domain(colorScale)

        // Construct Viz
        svg.selectAll(".map")
            .data(aggData)
            .enter().append("rect")
            .attr("x", d => x(d.Hour))
            .attr("y", d => y(d.Day))
            .attr("width", 0)
            .attr("height", 0)
            .attr("rx", 100)
            .style("fill", d => myColor(d.Availability))
            .style("stroke-opacity", "0")

        //Add animations
        svg.selectAll("rect")
            .data(aggData)
            .transition()
            .duration(800)
            .attr("width", x.bandwidth()-1)
            .attr("height", y.bandwidth()-1)
            .attr("rx", 3)

        //Filter the amount of hours displayed on the barchart.
        if (lastHour >=filteredStartHour && lastHour<= filteredEndHour) {
            let leftPosition = x(lastHour)
            let rightPosition = (lastHour === filteredEndHour? width : x(lastHour +1)) -1
            let topPosition = 0.5
            let bottomPosition = (blockLength * 7) -1.5
            
            function drawLine(x1,y1,x2,y2) {
                svg.append("line")
                .attr("x1", x1 )
                .attr("y1", y1)
                .attr("x2", x2)
                .attr("y2", y2)
                .style("stroke", lineColour)
                .style("stroke-width",lineWidth)
                .style("stroke-linecap","round")
                .attr("opacity","1")
                .attr()
            }
            
            //Add Left Line
            drawLine(leftPosition,topPosition,leftPosition,bottomPosition)
            //Add Right Line
            drawLine(rightPosition,topPosition,rightPosition,bottomPosition)
            //Add Top Line
            drawLine(leftPosition,topPosition,rightPosition,topPosition)
            //Add Bottom Line
            drawLine(leftPosition,bottomPosition,rightPosition,bottomPosition)

            //Add Line Label Background
            svg.append("rect")
                .attr("x",rightPosition)
                .attr("y", bottomPosition - blockLength/2)
                .attr("height", blockLength/2)
                .attr("width", "24")
                .attr("fill", "white")
                .attr("opacity","0.65")

            //Add Line Label
            svg.append("text")
                .attr("x", rightPosition)
                .attr("y", bottomPosition -2)
                .attr('text-anchor', 'right')
                .attr("font-size",fontSize)
                .attr("fill", "#4d678f")
                .text(`Now`);
        }

        timeSliderAnimation()
        function timeSliderAnimation()  {
            svg.selectAll("line")
                .transition()
                .duration(1500)
                .attr("opacity","1")
                .transition()
                .duration(1500)
                .attr("opacity","0.5")
                .on("end",timeSliderAnimation)
        }        
    }

    render() {
        return <svg id={this.props.mobile ? 'heatmap-mobile' : 'heatmap'} ref={
                node => this.node = node
            }
            width={this.props.width}
            height={this.props.height}></svg>
    }
}

export default HeatMap;
